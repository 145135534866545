
import { defineComponent, reactive } from 'vue';
import { useInject } from '@/utils/inject';
import { DataItem } from '@/types/app.global';
import SearchTable from '@/components/table/SearchTable.vue';

export default defineComponent({
  name: 'DistributionFlow',
  components: { SearchTable },
  setup() {
    const { https } = useInject();
    const tableOpts = {
      pageable: false,
      search: {
        criteria: {
          cols: [
            {
              field: 'distributionNo',
              label: '配货单号',
              choice: true
            },
            {
              field: 'orderCode',
              label: '订单号',
              choice: true
            },
            {
              field: 'outCode',
              label: '出库单号',
              choice: true
            },
            {
              field: 'logisticNo',
              label: '物流单号',
              choice: true
            }
          ]
        },
        remote: {
          action: https?.mall.listDistributionFlow
        }
      },
      cols: [
        {
          field: 'distributionNo',
          label: '配货单号',
          ability: 'copy'
        },
        {
          field: 'orderCode',
          label: '订单号',
          ability: 'copy'
        },
        {
          field: 'outCode',
          label: '出库单号',
          ability: 'copy'
        },
        {
          field: 'logisticNo',
          label: '物流单号',
          ability: 'copy'
        },
        {
          field: 'num',
          label: '数量',
          width: 120
        },
        {
          field: 'com',
          label: '快递公司',
          width: 160
        },
        {
          field: 'statusDesc',
          label: '状态',
          width: 120
        }
      ],
      commands: [] as any[]
    };

    const deviceOpts = reactive({
      visible: false,
      list: [] as string[]
    });

    const showDeviceList = (data: DataItem) => {
      if (data.sns) {
        deviceOpts.list = data.sns;
        deviceOpts.visible = true;
      }
    };

    tableOpts.commands.push({ label: '机具列表', action: showDeviceList });
    return { tableOpts, deviceOpts };
  }
});
