import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_table = _resolveComponent("search-table")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_search_table, { opts: _ctx.tableOpts }, null, 8, ["opts"]),
    _createVNode(_component_a_drawer, {
      closable: false,
      visible: _ctx.deviceOpts.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deviceOpts.visible) = $event)),
      placement: "left",
      width: "25%",
      title: "设备列表"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_list, {
          size: "small",
          bordered: "",
          "data-source": _ctx.deviceOpts.list
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data-source"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}